import {css} from "@emotion/core"
import React from "react"
import {Layout} from "../components"
import {RecoilRoot} from "recoil"
import Theme from "../styles/theme"

const NotFound = (): JSX.Element => {
  return (
    <RecoilRoot>
      <div css={NotFoundStyling}>
        <Layout title="Contact us">
          <div className="not-found">
            <h1>
              Well, that was <br /> kind of unexpected
            </h1>
            <hr />
            <p>
              It looks like that page that you requested isn’t avalaible
              anymore. Instead, take a look at our latest projects or go back to
              the homepage.
            </p>

            <div className="actions">
              <a href="/">Homepage</a>
            </div>
          </div>
        </Layout>
      </div>
    </RecoilRoot>
  )
}

export default NotFound

const NotFoundStyling = css`
  .header {
    g#homepage {
      fill: black !important;
    }
    .burger {
      span {
        background: black;
      }
    }
  }
  main {
    background-color: white;

    .not-found {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      max-width: 72rem;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      @media ${Theme.breakpoints.notDesktop} {
        max-width: 50rem;
      }

      h1 {
        margin-bottom: 2.5rem;
        font-size: 7rem;
        font-weight: 600;
        line-height: 10.6rem;

        @media ${Theme.breakpoints.notDesktop} {
          font-size: 2.6rem;
          line-height: 3.9rem;
        }
      }

      hr {
        background: black;
        width: 4.7rem;
        height: 2px;
      }

      p {
        margin: 4rem 0 5rem;
        font-size: 2rem;
        opacity: 0.9;
        line-height: 3.4rem;
        font-weight: 300;

        @media ${Theme.breakpoints.notDesktop} {
          font-size: 1.3rem;
          line-height: 2rem;
          margin-bottom: 4.2rem;
        }
      }

      .actions {
        width: 100%;

        a {
          display: inline-block;
          padding: 1.7rem;
          cursor: pointer;
          text-align: center;
          font-size: 1.2rem;
          font-weight: 500;
          border: 0.8rem solid ${Theme.colors.buttoncolors.grayBorder};
          color: ${Theme.colors.fontcolors.secondary};
          min-width: 28rem;

          @media ${Theme.breakpoints.medium} {
            font-size: 1.8rem;
          }

          @media ${Theme.breakpoints.mediumDown} {
            margin-bottom: 1rem;
          }

          &:hover {
            font-weight: bold;
          }
        }
      }
    }
  }
`
